import React from "react"
import { Link } from "gatsby"

import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import HeaderImg from "../img/header2.jpg"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}))

const IndexPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO title="Home" />
      <div>
        <img src={HeaderImg} alt="East West Culture and Art Center" />
        <Typography variant="h4" gutterBottom>
          Coming Soon: 2020春节元宵联欢会
        </Typography>
        <Typography paragraph>
          东西方文化艺术中心一年一度的春节联欢会是一场以自娱自乐为形式，轻松愉快为目的，新朋老友欢聚一堂，辞旧迎新，分享美食，展示才艺的娱乐晚会。参与者请盛装出席，每人带一份菜肴分享。愿出节目的朋友请提前告知节目内容。每个节目时间控制在3分钟左右，不能超过4分钟。组委会保留删减节目内容的权利。
        </Typography>
        <Typography paragraph>
          参与人数限制在50以内，名额报满为止。未成年者谢绝参加。
        </Typography>
        <Typography paragraph>
          晚会时间：2020年2月9日 5:00pm
          <br />
          晚会地点：东西方文化艺术中心
        </Typography>
        <Typography paragraph>
          6158 Innovation Way
          <br />
          Carlsbad, CA 92009
        </Typography>
        <Typography paragraph>
          报名方式：请将参加人数，节目内容和简介通过微信或邮件方式交于联系人
          <br />
          联系人：赵红
          <br />
          微信：hanna_111
          <br />
          邮件：info@eastwestcultureandart.com
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography paragraph>
                东西方文化艺术中心欢迎您的光临
                <br />
                See what's coming up
              </Typography>
              <Link to="/calendar">
                <Button variant="contained" color="secondary">
                  Event Calendar
                </Button>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography paragraph>
                Have an event?
                <br />
                Contact us to reserve the space.
              </Typography>
              <Link to="/contact">
                <Button variant="contained" color="primary">
                  Contact Us
                </Button>
              </Link>
            </Paper>
          </Grid>
        </Grid>
        <Divider />
      </div>
    </Layout>
  )
}

export default IndexPage
